<template>
  <div id="st-container" class="st-container" :class="`theme${kindTheme}`">
    <NavMenu/>
    <ContentWrapper/>
    <bottom-left-call v-if="(currentPage==='home' || currentPage === 'search-result') && !isFCLAgent"/>
    <bottom-right-call-info v-if="currentPage==='home'"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import agencyMarketer from '@/mixins/agencyMarketer';

export default {
  name: 'Container',
  components: {
    NavMenu: () => import('@/components/menu/NavMenu'),
    ContentWrapper: () => import('@/components/content/ContentWrapper'),
    BottomLeftCall: () => import('@/components/content/BottomLeftCall'),
    BottomRightCallInfo: () => import('@/components/content/CallerInfoModal/BottomRightCallInfo'),
  },
  mixins: [agencyMarketer],
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      currentPage: 'GET_CURRENT_PAGE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
    }),
  },
};
</script>

<style>
@media (min-width: 1200px){
  .container {
    max-width: 1500px;
  }
}
</style>
